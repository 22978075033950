<!-- 库存管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditReady()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiugai" font-size="27px;"/>
                            <span class="txt">{{msg('预备库存')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditLeast()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiugai1" font-size="27px;"/>
                            <span class="txt">{{msg('保底库存')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditBuyLink()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiangqingxiugai" font-size="27px;"/>
                            <span class="txt">{{msg('采购链接')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditRemark()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('备注')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditCreated()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chuhuoshijian" font-size="27px;"/>
                            <span class="txt">{{msg('创建时间')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditStockStatus()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu1" font-size="27px;"/>
                            <span class="txt">{{msg('下架')}}</span>
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template>
                    <el-form-item :label="msg('统计天数')">
                        <!-- <el-input v-model="query.days"></el-input> -->
                        <el-input-number v-model="query.days" :min="0" :max="30"></el-input-number>
                    </el-form-item>
                    <el-form-item :label="msg('库存编号')">
                        <el-input type="textarea"  :rows="3" v-model="query.stockCode" style="margin-top:0px;">
                        </el-input>
                        <!-- <el-input v-model="query.stockCode"></el-input> -->
                    </el-form-item>
                    <el-form-item :label="msg('货号')">
                        <el-input type="textarea"  :rows="3" v-model="query.variationSku" style="margin-top:0px;">
                        </el-input>
                        <!-- <el-input v-model="query.variationSku"></el-input> -->
                    </el-form-item>
                    <el-form-item :label="msg('架位号')">
                        <el-input v-model="query.shelfCode"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('SKU')">
                        <el-input v-model="query.sku"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('标题')">
                        <el-input v-model="query.productTitle"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('规格')">
                        <el-input v-model="query.productSpec"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('所在仓库')">
                        <el-select v-model="query.storeId" clearable  placeholder="请选择" size="mini">
                            <el-option
                                v-for="item in stores"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('归属人')">
                        <el-input v-model="query.fullname"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('采购链接')">
                        <el-input v-model="query.buyLink"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注')">
                        <el-input v-model="query.remark"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('库存状态')">
                        <el-select v-model="query.qtyStatus" clearable placeholder="请选择">
                            <el-option
                            v-for="item in hqStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('仓储状态')">
                        <el-select v-model="query.stockStatus" clearable placeholder="请选择">
                            <el-option
                            v-for="item in dicts.stockStatus1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('仓储类型')">
                        <el-select v-model="query.storeType" clearable placeholder="请选择">
                            <el-option
                            v-for="item in dicts.stockStoreType2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('创建时间')" label-width="80px">
                        <el-date-picker  v-model="query.Date" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list(2)" size="mini">{{msg('搜索统计')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                size="mini"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="stockDetails"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'StockDetail',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        // let date = new Date();
        // let dateL = date.getTime();
        // let endDate = dateL - (30*24*3600*1000);
        
        // this.query.Date = [
        //     this.$kit.fmtDateTime(new Date(endDate)),
        //     this.$kit.fmtDateTime(date),
        // ];

        this.buildColumns();
        this.getStores();
    },
    data(){
        return {
            query:{
                length: 100,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                Date: [], 
                stockCode:null,
                shelfCode:null,
                sku:null,
                variationSku:null,
                productTitle:null,
                productSpec:null,
                storeId:null,
                fullname:null,
                buyLink:null,
                remark:null,
                stockStatus:null,
                storeType:null,
                qtyStatus:null,
                days:0,
            },
            stores: [],
            showQuery: true,
            curr: null,
            stockDetails: [],
            columns: [],
            hqStatus:[
                {
                    value: 1,
                    label: '充足'
                },
                {
                    value: 2,
                    label: '不足'
                },
                {
                    value: 3,
                    label: '采购中'
                },
                {
                    value: 4,
                    label: '转运中'
                },
            ],
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 60},
                {field: 'created', title: this.msg('创建时间'), minWidth: 100, 
                },
                {field: 'stockCode', title: this.msg('库存编号'), minWidth: 100, sortable: true,
                },
                {field: 'variationSku', title: this.msg('货号'), minWidth: 100,sortable: true, },
                {field: 'sku', title: this.msg('SKU'), minWidth: 100, sortable: true,},
                {field: 'productTitle', title: this.msg('货物标题'), minWidth: 120,sortable: true, },
                {field: 'productSpec', title: this.msg('货物规格'), minWidth: 100,sortable: true,},
                {field: 'productQty', title: this.msg('货物件数'), minWidth: 60, sortable: true,},
                {field: 'shelfCode', title: this.msg('架位号'), minWidth: 80, sortable: true,},
                {field: 'buyLink', title: this.msg('采购链接'), minWidth: 120,sortable: true, },
                {field: 'ready', title: this.msg('预备库存'), minWidth: 50, sortable: true,},
                {field: 'caigouQty', title: this.msg('采购库存'), minWidth: 50, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let caigouQty = row.caigouQty&&row.caigouQty>0?row.caigouQty:0;
                            return [
                                <span>{caigouQty}</span>
                            ];
                        },
                    },
                },
                {field: 'zhuanyunQty', title: this.msg('转运库存'), minWidth: 50, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let zhuanyunQty = row.zhuanyunQty&&row.zhuanyunQty>0?row.zhuanyunQty:0;
                            return [
                                <span>{zhuanyunQty}</span>
                            ];
                        },
                    },
                },
                {field: 'least', title: this.msg('保底库存'), minWidth: 50,sortable: true, },
                {field: 'zongQty', title: this.msg('总出货量'), minWidth: 50, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let zongQty = row.zongQty&&row.zongQty>0?row.zongQty:0;
                            return [
                                <span>{zongQty}</span>
                            ];
                        },
                    },
                },
                {field: 'pingJun', title: this.msg('日平均量'), minWidth: 50, sortable: true,
                    // slots: {
                    //     default: ({ row }) => {
                    //         return [
                    //             <span>{row.pingjun>0?row.pingjun:0}</span>
                    //         ];
                    //     },
                    // },
                },
                {field: 'baoGong', title: this.msg('库存保供'), minWidth: 50,sortable: true, 
                    // slots: {
                    //     default: ({ row }) => {
                    //         return [
                    //             <span>{row.baogong>0?row.baogong:0}</span>
                    //         ];
                    //     },
                    // },
                },
                {field: 'statusLabel', title: this.msg('库存状态'), minWidth: 80, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let label = row.statusLabel;
                            let type = null;
                            if(label=='充足'){
                                type = 'success';
                            }else if(label=='不足'){
                                type = 'danger';
                            }else if(label=='采购中'){
                                type = 'warning';
                            }else if(label=='转运中'){
                                type = 'warning';
                            }
                            return [
                                <el-tag type={type}>{label}</el-tag>
                            ];
                        },
                    },
                },
                {field: 'stockStatusLabel', title: this.msg('仓储状态'), minWidth: 70, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let label = row.stockStatusLabel;
                            return [
                                <el-tag type='success'>{label}</el-tag>
                            ];
                        },
                    },
                },
                {field: 'storeTypeLabel', title: this.msg('仓储类型'), minWidth: 80, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let label = row.storeTypeLabel;
                            return [
                                <el-tag type='success'>{label}</el-tag>
                            ];
                        },
                    },
                },
                {field: 'storeName', title: this.msg('所在仓库'), minWidth: 80, sortable: true,
                },
                {field: 'fullname', title: this.msg('归属人'), minWidth: 70, sortable: true,
                },
                {field: 'remark', title: this.msg('备注'), minWidth: 60,sortable: true, },
            ]
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores.splice(0,this.stores.length);
                        this.stores = result.data;
                    }
                });
        },
        list(type){

            if(this.query.stockCode){
                let cs = this.query.stockCode.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["stockCode"] = css.join(",");
            }else{
                this.query["stockCode"] = '';
            }

            if(this.query.variationSku){
                let cs = this.query.variationSku.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["variationSku"] = css.join(",");
            }else{
                this.query["variationSku"] = '';
            }

            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.stock.stockDetailTongji+"?type="+type, param)
                .then((result) => {
                    if(result.status){
                        this.stockDetails = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        doExport(){
            // this.$refs.dataTable.exportData({ filename:"航空清关信息",type: 'csv' })
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doEditReady(){//预备库存
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");

            this.$prompt('请输入预备库存', this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                var patten = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
                if(value && !patten.test(value)){
                    this.$message(this.msg('格式不正确，只能输入数字'));
                    return;
                }
                this.$axios.post(this.$kit.api.stock.editDetailReady+"?ready="+value, ids)
                .then((result) => {
                    if(result.status){
                        this.query.days = 0;
                        this.list();
                    }
                    this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        doEditLeast(){//保底库存
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");

            this.$prompt('请输入保底库存', this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                var patten = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
                if(value && !patten.test(value)){
                    this.$message(this.msg('格式不正确，只能输入数字'));
                    return;
                }
                this.$axios.post(this.$kit.api.stock.editDetailLeast+"?least="+value, ids)
                .then((result) => {
                    if(result.status){
                        this.query.days = 0;
                        this.list();
                    }
                    this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        doEditStockStatus(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");
            var _this = this;
            this.$confirm("是否进行下架操作？", this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.stock.editStockStatus, ids)
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        this.query.days = 0;
                        _this.list(false);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doEditBuyLink(){//采购链接
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");

            this.$prompt('请输入采购链接', this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                console.log('value:'+value);
                let para = {
                    ids:ids,
                    buyLink:value
                }
                this.$axios.post(this.$kit.api.stock.editDetailBuyLink, para)
                .then((result) => {
                    if(result.status){
                        this.query.days = 0;
                        this.list();
                    }
                    this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        doEditRemark(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");

            this.$prompt('请输入备注', this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.stock.editDetailRemark+"?remark="+value, ids)
                .then((result) => {
                    if(result.status){
                        this.query.days = 0;
                        this.list();
                    }
                    this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        doEditCreated(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
                if(selectes.length==0){
                    this.$message(this.msg('请选中一条数据进行操作'));
                    return;
                }
            let ids = selectes.map(e => e.id).join(",");
            var _this = this;
            this.$confirm(this.msg("确定更新创建时间为当前日期？"), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.stock.editStockCreated,ids)
                .then((result) => {
                    if(result.status){
                        _this.$message(result.msg || _this.msg('更新成功'));
                        _this.list(false);
                    }else{
                        _this.$message(result.msg || _this.msg('更新失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
