<!-- 优惠券-->
<template>
  <div>
        <ctrl-box class="ctrl-box" ::data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('开户公司')">
                        <el-input v-model="scope.query.company" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('客户账号')">
                        <el-input v-model="scope.query.username" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('客户名称')">
                        <el-input v-model="scope.query.fullname" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('券名')">
                        <el-input v-model="scope.query.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('状态')">
                        <!-- <el-input v-model="scope.query.status" ></el-input> -->
                        <el-select v-model="scope.query.status" placeholder="请选择">
                            <el-option :key="0" label="未使用" :value="0"></el-option>
                            <el-option :key="1" label="已使用" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('相关单号')">
                        <el-input v-model="scope.query.code" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('操作人')">
                        <el-input v-model="scope.query.createName" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('赠送时间')">
                        <el-date-picker  v-model="scope.query.Date" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('过期时间')">
                        <el-date-picker  v-model="scope.query.ExpireTime" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('使用时间')">
                        <el-date-picker  v-model="scope.query.UsedTime" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="coupons"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'coupon',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                company:null,
                username:null,
                fullname:null,
                name:null,
                status:null,
                code:null,
                createName:null,
                Date: [],
                ExpireTime:[],
                UsedTime:[],
            },
            showQuery: false,
            columns: [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'created', title: this.msg('赠送时间'), minWidth:130, sortable: true},
                {field: 'company', title: this.msg('开户公司'), minWidth: 120, sortable: true},
                {field: 'username', title: this.msg('客户账号'), minWidth: 120, sortable: true},
                {field: 'fullname', title: this.msg('客户名称'), minWidth: 120, sortable: true},
                {field: 'name', title: this.msg('券名'), minWidth: 120, sortable: true},
                {field: 'amount', title: this.msg('抵扣金额(元)'), minWidth: 120, sortable: true},
                {field: 'weight', title: this.msg('限重(KG)'), minWidth: 120, sortable: true},
                {field: 'status', title: this.msg('状态'), minWidth: 120, sortable: true,
                    slots: {
                    // 使用 JSX 渲染
                    default: ({ row }) => {
                            let type = row.status;
                            return [
                                <el-tag type={type==1?'danger':'success'}>{type==1?'已使用':'未使用'}</el-tag>
                            ];
                        }
                    }
                },
                {field: 'expireTime', title: this.msg('过期时间'), minWidth:130, sortable: true},
                {field: 'usedTime', title: this.msg('使用时间'), minWidth:130, sortable: true},
                {field: 'code', title: this.msg('相关单号'), minWidth: 120, sortable: true},
                {field: 'remark', title: this.msg('赠送说明'), minWidth: 120, sortable: true},
                {field: 'createName', title: this.msg('操作人'), minWidth: 80, sortable: true},
            ],
            coupons: [],
            dgTitle: '',
            dgVisible: false,
            curr: null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods: {
        list(){
            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.coupon.list, param)
                .then((result) => {
                    if(result.status){
                        this.coupons = result.data.list;
                        this.coupons.forEach(e=>{
                            if(e.amount){
                                e.amount = (e.amount/100).toFixed(0);
                            }
                            if(e.weight){
                                e.weight = (e.weight/1000).toFixed(0);
                            }
                        })
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doExport(){
            // this.$refs.dataTable.openExport({ isPrint: false })
            this.$refs.dataTable.exportData({ filename:"优惠券信息",type: 'csv' })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
