<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('清关转换')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('导入')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row :gutter="10"  class="ctrl-wrap">
            <el-col :sm="24" :md="5">
                <el-button size="mini"
                    @click="doSelectFile()" type="success" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="iconfont icon-xuanzewenjian" 
                            style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('上传文件')}}</span>
                        </transition>
                </el-button>
                <div ref="fileArea" style="display:none;">
                </div>
            </el-col>  
            <el-col :sm="24" :md="5">
                <el-button size="mini"
                    @click="doExport()" type="success" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="iconfont icon-xuanzewenjian" 
                            style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('导出')}}</span>
                        </transition>
                </el-button>
            </el-col>  
        </el-row>
        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="results"
                :columns="columns"
            ></vxe-grid>
        </div>
    </div>
</template>
<script>
import XLSX from "xlsx";
export default {
    name: 'WalletImport',
    components: {},
    data(){
        return {
            excelHeads: [], //从excel解析出数据的表头
            excelData: null, //从excel解析出的数据
            results:[],
            columns:[],
        }
    },
    computed: {
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 500;
            }
        },
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
    },
    mounted(){
        this.results = [];
        this.excelData = [];
        this.buildColumns();
    },
    methods:{
        init(){
            this.results = [];
            this.excelData = [];
            this.buildColumns();
        },
        buildColumns(){
            this.columns = [
                {field: 'row1', title: this.msg('网络渠道'), minWidth: 80},
                {field: 'row2', title: this.msg('目的地'), minWidth: 70},
                {field: 'row3', title: this.msg('运单号'), minWidth: 100},
                {field: 'row4', title: this.msg('报关号'), minWidth: 100},
                {field: 'row5', title: this.msg('参考号'), minWidth: 100},
                {field: 'row6', title: this.msg('件数'), minWidth: 50},
                {field: 'row7', title: this.msg('重量'), minWidth: 50},
                {field: 'row8', title: this.msg('物品描述'), minWidth: 150},
                {field: 'row9', title: this.msg('物品数量'), minWidth: 130},
                {field: 'row10', title: this.msg('物品单价'), minWidth: 130},
                {field: 'row11', title: this.msg('收件人'), minWidth: 40},
                {field: 'row12', title: this.msg('收件地址'), minWidth: 40},
                {field: 'row13', title: this.msg('收件电话'), minWidth: 40},
                {field: 'row14', title: this.msg('收件邮编'), minWidth: 40},
                {field: 'row15', title: this.msg('代收货款'), minWidth: 40},
                {field: 'row16', title: this.msg('备注'), minWidth: 40},
                {field: 'row17', title: this.msg('备用一'), minWidth: 40},
                {field: 'row18', title: this.msg('备用二'), minWidth: 40},
            ]
        },
        doExport(){
            this.$refs.dataTable.exportData({ filename:"海快信息",type: 'xlsx' })
        },
        doSelectFile(){  //点击选择文件
            this.$refs.fileArea.innerHTML = "";
            var input = document.createElement("input");
            input.type="file";
            var _this = this;
            input.onchange=this.getUploadFile;    
            this.$refs.fileArea.appendChild(input);
            input.click();
        },
        getUploadFile(e) {  //解析上传的excel
            let that = this;

            this.excelData = null
            //拿到所导入文件的名字
            let fileName = e.target.files[0];
            //定义reader，存放文件读取方法
            let reader = new FileReader();
            //启动函数
            reader.readAsBinaryString(fileName)
            //onload在文件被读取时自动触发
            reader.onload = (e) => {
                //workbook存放excel的所有基本信息
                let workbook = XLSX.read(e.target.result, {type: 'binary'})
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames
                //存放json数组格式的表格数据
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let json = XLSX.utils.sheet_to_json(worksheet, {header:1})

                var heads = json[0];
                that.excelData = json.splice(1);
                that.excelHeads = heads;
                that.$refs.fileArea.innerHTML = "";
                this.doUpload();
            };
        },
        doUpload(){
            // window.open(this.$kit.api.category.imports+"?data="+encodeURIComponent(JSON.stringify(this.exportParams))+"&ids="+this.exportIds+"&isMatch="+(data.isMatch?1:0),"_blank")
            this.$axios.post(this.$kit.api.category.imports+"?type=0", this.excelData)
                .then((result) => {
                    this.$message(result.msg);
                    this.results = result.data;
                });
        },
    }
}
</script>
<style lang="scss" scoped>

</style>