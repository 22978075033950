<!-- 品名品类管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap">
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="add()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="edit()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="batchEdit()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-pilianggengxin1" font-size="27px;"/>
                            <span class="txt">{{msg('批量更新')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doImport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daoru3" font-size="27px;"/>
                            <span class="txt">{{msg('导入')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doImport2()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daoru3" font-size="27px;"/>
                            <span class="txt">{{msg('导入2')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('品名')">
                        <el-input v-model="scope.query.productName" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('类别')">
                        <el-input v-model="scope.query.title" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('创建时间')" label-width="80px">
                        <el-date-picker  v-model="query.Date" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="productNames"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen"  @opened="openedCategoryEdit"
        :close-on-click-modal="false" 
        width="600px" >
        <edit :category="categoryEdit" ref="dgTitleRef" @close-dg="dgVisibleClose"/>
    </el-dialog>

    <el-dialog :title="dgTitle" :visible.sync="dgVisibleBatch" :fullscreen="isMinScreen"  @opened="openedCategoryEditBatch"
        :close-on-click-modal="false" 
        width="600px" >
        <batch-edit :batchIds="batchIds" ref="dgTitleRefBatch" @close-dg="dgVisibleCloseBatch"/>
    </el-dialog>

    <el-dialog
        :title="msg('清关转换')" :fullscreen="isMinScreen"
        :visible.sync="importVisible"
        :close-on-click-modal="false"
        @opened="openedImportVisible"
        :before-close="doCloseImport"
        width="50%">
            <category-import ref="importVisibleRef" @close-dg="importVisible=false"/>
    </el-dialog>

    <el-dialog
        :title="msg('清关转换2-多行')" :fullscreen="isMinScreen"
        :visible.sync="importVisible2"
        :close-on-click-modal="false"
        @opened="openedImportVisible2"
        :before-close="doCloseImport2"
        width="50%">
            <category-import2 ref="importVisibleRef2" @close-dg="importVisible2=false"/>
    </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Edit from "./CategoryEdit.vue";
import BatchEdit from "./CategoryBatchEdit.vue";
import CategoryImport from "./CategoryImport.vue";
import CategoryImport2 from "./CategoryImport2.vue";

export default {
    name: 'category',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Edit:Edit,
        BatchEdit:BatchEdit,
        CategoryImport:CategoryImport,
        CategoryImport2:CategoryImport2
    },
    mounted:function(){
        this.buildColumns();
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                productName:null,
                title:null,
                Date: [], 
            },
            showQuery: false,
            importVisible:false,
            importVisible2:false,
            curr: null,
            columns: [],
            productNames: [],
            dgTitle: '新增',
            dgVisible: false,
            dgVisibleBatch:false,
            categoryEdit: {
                productName:null,
                title:null,
                minPrice:0,
                maxPrice:0,
            },
            batchIds:null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 30},
                {field: 'id', title: this.msg('ID'), minWidth: 40, sortable: true},
                {field: 'productName', title: this.msg('品名'), minWidth: 160, sortable: true},
                {field: 'title', title: this.msg('类别'), minWidth: 80, sortable: true,
                },
                {field: 'minPrice', title: this.msg('最低价格'), minWidth: 60, sortable: true,
                },
                {field: 'maxPrice', title: this.msg('最高价格'), minWidth: 60, sortable: true,
                },
                {field: 'createrName', title: this.msg('创建人'), minWidth: 60, sortable: true,
                },
                {field: 'created', title: this.msg('创建时间'), minWidth:100, sortable: true,
                },
            ]
        },
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var param = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.category.list, param)
                .then((result) => {
                    if(result.status){
                        this.productNames = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        openedCategoryEdit(){
            this.$nextTick(() => {
                this.$refs.dgTitleRef.init();
            });
        },
        openedCategoryEditBatch(){
            this.$nextTick(() => {
                this.$refs.dgTitleRefBatch.init();
            });
        },
        openedImportVisible(){
            this.$nextTick(() => {
                this.$refs.importVisibleRef.init();
            });
        },
        openedImportVisible2(){
            this.$nextTick(() => {
                this.$refs.importVisibleRef2.init();
            });
        },
        add(){
            this.categoryEdit = {
                productName:null,
                title:null,
                minPrice:0,
                maxPrice:0,
            };
            this.dgVisible = true;
        },
        edit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.categoryEdit = selectes[0];

            this.dgVisible = true;
        },
        batchEdit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.batchIds = selectes.map(e => e.id).join(",");
            this.dgVisibleBatch = true;
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        dgVisibleClose(){
            this.categoryEdit = {
                productName:null,
                title:null,
                minPrice:0,
                maxPrice:0,
            };
            this.dgVisible = false;
            this.list();
        },
        dgVisibleCloseBatch(){
            this.dgVisibleBatch = false;
            this.batchIds = null;
            this.list();
        },
        importVisibleDg(){
            this.importVisible = false;
            this.list();
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.msg('确定要删除选中的数据吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.category.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        _this.$message('成功删除'+result.data+'条记录');
                        _this.list();
                    }else{
                        _this.$message(result.msg || _this.msg('删除失败'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
        doImport(){
            this.importVisible = true;
        },
        doImport2(){
            this.importVisible2 = true;
        },
        doCloseImport(done){
            this.list();
            done();
        },
        doCloseImport2(done){
            this.list();
            done();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
