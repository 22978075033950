<!-- 集运地址管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box" ::data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <i class="iconfont icon-sousuo font22" ></i>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doAdd()" v-if="hasPermission('siteAddress:add')">
                        <div class="wrap" >
                            <i class="iconfont icon-add font22" ></i>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()" v-if="hasPermission('siteAddress:edit')">
                        <div class="wrap" >
                            <i class="iconfont icon-edit font22" ></i>
                            <span class="txt">{{msg('编辑')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" type="danger" @click="doDelete()" v-if="hasPermission('siteAddress:delete')">
                        <div class="wrap" >
                            <i class="iconfont icon-delete font22"></i>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('日期')">
                        <el-date-picker  v-model="scope.query.Date" type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="sites"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

        <el-dialog
            :title="editTitle"
            :visible.sync="editVisiable"
            :close-on-click-modal="false"
            width="40%">
            <el-form ref="form" :model="siteForm" label-width="80px">
                <el-form-item :label="msg('站点')">
                    <el-input v-model="siteForm.siteName" :placeholder="msg('请输入站点名称')">{{siteForm.siteName}}</el-input>
                </el-form-item>
                <el-form-item :label="msg('仓库')">
                    <el-input v-model="siteForm.storeName">{{siteForm.storeName}}</el-input>
                </el-form-item>
                <el-form-item :label="msg('收件人')">
                    <el-input v-model="siteForm.receiver" :placeholder="msg('请输入收件人')">{{siteForm.receiver}}</el-input>
                </el-form-item>
                <el-form-item :label="msg('联系电话')">
                    <el-input v-model="siteForm.tel" :placeholder="msg('请输入联系电话')">{{siteForm.tel}}</el-input>
                </el-form-item>
                <el-form-item :label="msg('收件地址')">
                    <el-input v-model="siteForm.address" :placeholder="msg('请输入收件地址')">{{siteForm.address}}</el-input>
                </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisiable = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doAddorEdit()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'site-address',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
            },
            showQuery: false,
            columns: [
                {type: 'checkbox', minWidth: 60},
                {field: 'id', title: this.msg('ID'), minWidth: 80, sortable: true},
                {field: 'siteName', title: this.msg('站点名'), minWidth: 130, sortable: true},
                {field: 'storeName', title: this.msg('仓库'), minWidth: 130, sortable: true},
                {field: 'receiver', title: this.msg('收件人'), minWidth: 130, sortable: true},
                {field: 'tel', title: this.msg('联系电话'), minWidth: 130, sortable: true},
                {field: 'address', title: this.msg('收件地址'), minWidth: 130, sortable: true},
                {field: 'createrName', title: this.msg('创建人'), minWidth: 120, sortable: true},
                {field: 'created', title: this.msg('创建时间'), minWidth:130, sortable: true}
            ],
            sites: [],
            editTitle:null,
            editVisiable: false,
            curr: null,
            siteForm:{
                siteName: null,
                storeName: null,
                receiver: null,
                tel: null,
                address: null,
            },
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        list(){
            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.site.addressList, param)
                .then((result) => {
                    if(result.status){
                        this.sites = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        doAdd(){
            this.siteForm = { 
                siteName: null,
                storeName: null,
                receiver: null,
                tel: null,
                address: null,
            };

            this.editTitle = this.msg("新增集运地址");
            this.editVisiable = true;
        },
        doEdit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.siteForm  = selectes[0];

            this.editTitle = this.msg("编辑集运地址");
            this.editVisiable = true;
        },
        doAddorEdit(){
            var url = null;
            if(this.siteForm.id==null){
                url = this.$kit.api.site.addressCreate;
            }else{
                url = this.$kit.api.site.addressUpdate;
            }

            var _this = this;
            this.$axios.post(url, this.siteForm)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || (_this.siteForm.id==null? _this.msg("创建成功"): _this.msg('更新成功')));
                        if(result.status){
                            _this.editVisiable = false;
                        }
                    }else{
                        _this.$message(result.msg || (_this.siteForm.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                    }
                });
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.fmt('确定要删除《%s》吗?', selectes.map(e => e.name).join(",")), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.site.addressDelete+"?ids="+selectes.map(e => e.id).join(","))
                    .then((result) => {
                        _this.$message(result.msg || _this.msg('删除成功'));
                        _this.list(true);
                    });
            }).catch((err) => {
                // console.log(err);
            });
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
