<!--导入采购链接-->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('采购链接')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('导入')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row :gutter="10"  class="ctrl-wrap">
            <el-col :sm="24" :md="12">
                <el-button size="mini"
                    @click="doSelectFile()" type="success" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="iconfont icon-xuanzewenjian" 
                            style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('上传文件')}}</span>
                        </transition>
                </el-button>
              
                <div ref="fileArea" style="display:none;">
                </div>
            </el-col>  
            <el-col :sm="24" :md="12">
                <el-button size="mini"
                    @click="doDownloadTpl()" type="primary" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('下载模板')}}</span>
                        </transition>
                </el-button>
            </el-col>  
        </el-row>
        <el-row :gutter="15">
            <el-table :data="results" :height="docHeight" style="width: 100%" size="mini" ref="dataTable" stripe>
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="shopName" :label="msg('店铺名')" min-width="140" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="xiapiTitle" :label="msg('商品店铺标题')" min-width="140" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="xiapiLink" :label="msg('商品店铺链接')" min-width="120" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="taobaoLink" :label="msg('商品采购链接')" min-width="120" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="productName" :label="msg('商品申报品名')" min-width="100" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark1" :label="msg('采购价格')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark2" :label="msg('货架号')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark3" :label="msg('规格')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="sku" :label="msg('SKU')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="variationSku" :label="msg('货号')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark4" :label="msg('尺码')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark5" :label="msg('采购数量')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark6" :label="msg('批次')" min-width="80" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="remark" :label="msg('备注')" min-width="60" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="msg" :label="msg('上传结果')" min-width="200" :show-overflow-tooltip="true"></el-table-column>
            </el-table>
        </el-row>
    </div>
</template>
<script>
import XLSX from "xlsx";
export default {
    name: 'WalletImport',
    components: {},
    data(){
        return {
            excelHeads: [], //从excel解析出数据的表头
            excelData: null, //从excel解析出的数据
            results:[],
        }
    },
    computed: {
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
    },
    mounted(){
        this.results = [];
        this.excelData = [];
    },
    methods:{
        doSelectFile(){  //点击选择文件
            this.$refs.fileArea.innerHTML = "";
            var input = document.createElement("input");
            input.type="file";
            var _this = this;
            input.onchange=this.getUploadFile;    
            this.$refs.fileArea.appendChild(input);
            input.click();
        },
        getUploadFile(e) {  //解析上传的excel
            let that = this;

            this.excelData = null
            //拿到所导入文件的名字
            let fileName = e.target.files[0];
            //定义reader，存放文件读取方法
            let reader = new FileReader();
            //启动函数
            reader.readAsBinaryString(fileName)
            //onload在文件被读取时自动触发
            reader.onload = (e) => {
                //workbook存放excel的所有基本信息
                let workbook = XLSX.read(e.target.result, {type: 'binary'})
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames
                //存放json数组格式的表格数据
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let json = XLSX.utils.sheet_to_json(worksheet, {header:1})

                var heads = json[0];
                that.excelData = json.splice(1);
                that.excelHeads = heads;
                that.$refs.fileArea.innerHTML = "";
                this.doUpload();
            };
        },
        doDownloadTpl(){  //下载一个excel模板，
           
            var titles = [
                ['店铺名','商品店铺标题','商品店铺链接','商品采购链接','商品申报品名','采购价格','货架号','规格','SKU','货号','尺码','采购数量','批次','备注'], 
            ];
 
            var filename = '采购链接模板.xlsx';

            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(titles);

            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, filename);
        },
        doUpload(){

            this.$axios.post(this.$kit.api.purchase.imports, this.excelData)
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg);
                        this.results = result.data;
                    }else{
                        let msg = result.msg;
                        if(msg.indexOf("uni_code")>-1){
                            let reg = /'[A-Z0-9]+'/;
                            let code = reg.exec(msg)[0];
                            this.$message(this.fmt("报关单号%s重复", [code]));
                        }else{
                            this.$message(result.msg);
                        }
                    }
                });
        },
    }
}
</script>
<style lang="scss" scoped>

</style>