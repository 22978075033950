<!-- 采购管理编辑-->
<template>
    <el-form ref="form" :model="purchase" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('店铺标题')">
                    <el-input v-model="purchase.xiapiTitle" :placeholder="msg('请输入店铺标题')" size="small" :disabled="disabled">
                        <template slot="append">必填项</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('店铺链接')">
                    <el-input v-model="purchase.xiapiLink" :placeholder="msg('请输入店铺链接')" size="small" :disabled="disabled">
                        <template slot="append">必填项</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('采购链接')">
                    <el-input v-model="purchase.taobaoLink" :placeholder="msg('请输入采购链接')" size="small">
                        <template slot="append">必填项</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('品名')">
                    <el-input v-model="purchase.productName" :placeholder="msg('请输入品名')" size="small">
                        <template slot="append">必填项</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('店铺名')">
                    <el-input v-model="purchase.shopName" :placeholder="msg('请输入店铺名')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('采购价格')">
                    <el-input v-model="purchase.remark1" :placeholder="msg('请输入采购价格')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('货架号')">
                    <el-input v-model="purchase.remark2" :placeholder="msg('请输入货架号')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('规格')">
                    <el-input v-model="purchase.remark3" :placeholder="msg('请输入规格')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('尺码')">
                    <el-input v-model="purchase.remark4" :placeholder="msg('请输入尺码')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('采购数量')">
                    <el-input v-model="purchase.remark5" :placeholder="msg('请输入采购数量')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('批次')">
                    <el-input v-model="purchase.remark6" :placeholder="msg('请输入批次')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('备注')">
                    <el-input v-model="purchase.remark" :placeholder="msg('请输入备注')" size="small"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </el-form>

</template>

<script>
export default {
    name: "purchaseEdit",
    components:{
    },
    data(){
        return {
            disabled:false,
        }
    },
    mounted(){
    },
    props:{
        purchase:Object,
    },
    methods:{
        init(){
            if(this.purchase.id || this.purchase.ids){
                this.disabled = true; 
            }else{
                this.disabled = false; 
            }
        },
        onSubmit(){
            var _this = this;

            let param = JSON.parse(JSON.stringify(this.purchase));
            delete param["created"];
            delete param["creater"];

            var url = null;
            if(this.purchase.id==null){
                url = this.$kit.api.purchase.create;
            }else{
                url = this.$kit.api.purchase.update;
            }

            this.$axios.post(url,param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg('操作成功'));
                        _this.$emit("close-dg", true);
                    }else{
                        console.log(result.msg);
                        _this.$message(result.msg || _this.msg('操作失败'));
                    }
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>