<!--编辑开户公司-->
<template>
    <el-form ref="form" :model="company" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('开户公司名')">
                    <el-input v-model.trim="company.name" :placeholder="msg('开户公司不能超过六个字符')"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('结算密码')">
                    <el-input v-if="this.company.id" :disabled="pwdEnable" v-model.trim="company.password" type="password" show-password :placeholder="msg('字母和数字组合')">
                        <el-button slot="append" icon="el-icon-edit" @click="editPassword">点击修改</el-button>
                    </el-input>
                    <el-input v-else v-model.trim="company.password" type="password" show-password :placeholder="msg('字母和数字组合')">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('口令')">
                    <el-input v-if="this.company.id" :disabled="wdEnable" v-model.trim="company.word" type="password" show-password :placeholder="msg('字母和数字组合')">
                        <el-button slot="append" icon="el-icon-edit" @click="editWord">点击修改</el-button>
                    </el-input>
                    <el-input v-else v-model.trim="company.word" type="password" show-password :placeholder="msg('字母和数字组合')">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('储值附件')">
                    <!-- <el-input v-model="company.password"></el-input> -->
                    <div style="display:flex;">
                        <el-upload class="avatar-uploader" :action="uploadUrl"
                                :show-file-list="false"
                                :with-credentials="true"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <!-- <el-row>
            <el-col v-if="company.created" :xs="24" :sm="12">
                <el-form-item :label="msg('创建人')">
                    <span>{{company.createrName}}</span>
                </el-form-item>
            </el-col>
            <el-col v-if="company.created" :xs="24" :sm="12">
                <el-form-item :label="msg('创建时间')">
                    <span>{{company.created}}</span>
                </el-form-item>
            </el-col>
        </el-row> -->
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'companyEdit',
    data(){
        return {
            uploadUrl: this.$kit.api.upload+"?type=company&press=0",
            pwdEnable:true,
            wdEnable:true,
        }
    },
    computed:{
      imageUrl:{
          get(){
              if(this.company.paymentImg){
                  return this.$kit.api.imgView+this.company.paymentImg;
              }
              return null;
          }
      }
    },
    props:{
        company: Object
    },
    methods:{
        init(){
            this.pwdEnable = true
            this.wdEnable = true
        },
        onSubmit(){
            if(!this.company.name){
                this.$message(this.msg("开户公司名不能为空"));
                return;
            }
            if(this.company.name.length > 4){
                this.$message(this.msg("开户公司不能过长"));
                return;
            }  

            //验证密码和口令是否包含中文
            let reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
            //字母和数字组合
            let zg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/;
            if(this.company.id==null){
                if(reg.test(this.company.password) || reg.test(this.company.word)){
                    this.$message(this.msg("结算密码和口令不能包含中文"));
                    return;
                }
                if(!zg.test(this.company.password) || !zg.test(this.company.word)){
                    this.$message(this.msg("结算密码和口令必须为字母和数字组合"));
                    return;
                }
                if(this.company.password.length > 8){
                    this.$message(this.msg("结算密码过长"));
                    return;
                }
                if(this.company.word.length > 8){
                    this.$message(this.msg("口令过长"));
                    return;
                }
            }else{
                if(this.company.password){
                    if(reg.test(this.company.password)){
                        this.$message(this.msg("结算密码不能包含中文"));
                        return;
                    }
                    if(!zg.test(this.company.password)){
                        this.$message(this.msg("结算密码必须为字母和数字组合"));
                        return;
                    }
                    if(this.company.password.length > 8){
                        this.$message(this.msg("结算密码过长"));
                        return;
                    }
                }
                if(this.company.word){
                    if(reg.test(this.company.word)){
                        this.$message(this.msg("口令不能包含中文"));
                        return;
                    }
                    if(!zg.test(this.company.word)){
                        this.$message(this.msg("口令必须为字母和数字组合"));
                        return;
                    }
                    if(this.company.word.length > 8){
                        this.$message(this.msg("口令过长"));
                        return;
                    }
                }
            }
            var _this = this;

            var url = null;
            if(this.company.id==null){
                url = this.$kit.api.company.create;
            }else{
                url = this.$kit.api.company.update;
            }

            this.$axios.post(url, this.company)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg('更新成功'));
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
            });

        },
        handleAvatarSuccess(res, file,fileList) {
            if(res.status){
                this.company.paymentImg = res.data;
            }else{
                this.$message(res.msg || this.msg("上传失败"));
            }
        },
        beforeAvatarUpload(){
            return true;
        },
        editPassword(){//编辑结算密码

            this.$prompt("请输入旧结算密码", this.msg('提示'), {
                    confirmButtonText: this.msg('确定'),
                    cancelButtonText: this.msg('取消'),
                    inputType: 'password',
                }).then(({ value }) => {
                    this.$axios.post(this.$kit.api.wallet.checkCompanyPwd+"?companyId="+this.company.id+"&pwd="+value)
                        .then((result) => {
                            if(result.status){
                                this.pwdEnable = false;
                            }else{
                                this.pwdEnable = true;
                                this.$message(this.msg('结算密码不正确'));
                            }
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
        },
        editWord(){
            this.$prompt("请输入旧口令", this.msg('提示'), {
                    confirmButtonText: this.msg('确定'),
                    cancelButtonText: this.msg('取消'),
                    inputType: 'password',
                }).then(({ value }) => {
                    this.$axios.post(this.$kit.api.wallet.checkCompanyWord+"?companyId="+this.company.id+"&pwd="+value)
                        .then((result) => {
                            if(result.status){
                                this.wdEnable = false;
                            }else{
                                this.wdEnable = true;
                                this.$message(this.msg('口令不正确'));
                            }
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
        },
    }
}
</script>
<style lang="scss">
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
    .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
</style>