<!-- 品类管理编辑-->
<template>
    <el-form ref="form" :model="category" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('品名')">
                    <el-input v-model="category.productName" :placeholder="msg('请输入品名')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('所属类别')">
                    <el-input v-model="category.title" :placeholder="msg('请输入类别')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('最低价格')">
                    <el-input-number v-model="category.minPrice" :precision="0" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('最高价格')">
                    <el-input-number v-model="category.maxPrice" :precision="0" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </el-form>

</template>

<script>
export default {
    name: "categoryEdit",
    components:{
    },
    data(){
        return {
        }
    },
    mounted(){
    },
    props:{
        category:Object,
    },
    methods:{
        init(){
        },
        onSubmit(){
            var _this = this;

            let param = JSON.parse(JSON.stringify(this.category));
            delete param["created"];
            delete param["creater"];

            var url = null;
            if(this.category.id==null){
                url = this.$kit.api.category.create;
            }else{
                url = this.$kit.api.category.update;
            }

            this.$axios.post(url,param)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg('update-ok'));
                        _this.$emit("close-dg", true);
                    }else{
                        _this.$message(result.msg || _this.msg('update-ok'));
                    }
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>