<!-- 采购链接管理-->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="add()" v-if="hasPermission('purchase:create')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="edit()" v-if="hasPermission('purchase:update')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="update()" v-if="hasPermission('purchase:update')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-pilianggengxin1" font-size="27px;"/>
                            <span class="txt">{{msg('批量更新')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doImport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daoru3" font-size="27px;"/>
                            <span class="txt">{{msg('导入')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出列表')}}</span>      
                        </div>         
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('purchase:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('店铺标题')">
                        <el-input v-model="scope.query.xiapiTitle" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('店铺名')">
                        <el-input v-model="scope.query.shopName" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('店铺链接')">
                        <el-input v-model="scope.query.xiapiLink" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('采购链接')">
                        <el-input v-model="scope.query.taobaoLink" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('品名')">
                        <el-input v-model="scope.query.productName" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('采购价格')">
                        <el-input v-model="scope.query.remark1" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('货架号')">
                        <el-input v-model="scope.query.remark2" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('规格')">
                        <el-input v-model="scope.query.remark3" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('SKU')">
                        <el-input v-model="scope.query.sku" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('货号')">
                        <el-input v-model="scope.query.variationSku" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('尺码')">
                        <el-input v-model="scope.query.remark4" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('采购数量')">
                        <el-input v-model="scope.query.remark5" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('批次')">
                        <el-input v-model="scope.query.remark6" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注')">
                        <el-input v-model="scope.query.remark" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('创建时间')" label-width="80px">
                        <el-date-picker  v-model="query.Date" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('引用时间')" label-width="80px">
                        <el-date-picker  v-model="query.usedTime" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                :cell-style="{width:'80px;',height:'80px'}"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="productNames"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen"  @opened="openedPurchaseEdit"
        :close-on-click-modal="false" 
        width="600px" >
        <edit :purchase="purchaseEdit" ref="dgTitleRef" @close-dg="dgVisibleClose"/>
    </el-dialog>

    <el-dialog
        :title="msg('导入采购链接')" :fullscreen="isMinScreen"
        :visible.sync="importVisible"
        :close-on-click-modal="false"
        :before-close="doCloseImport"
        width="90%">
            <purchase-import @close-dg="importVisible=false"/>
    </el-dialog>

    <el-dialog
            :title="msg('批量更新')"
            :visible.sync="updateVisiable"
            :close-on-click-modal="false"
            width="50%">
            <el-form ref="form" :model="purchaseForm" label-width="80px">
                <el-form-item :label="msg('采购链接')">
                    <el-input v-model="purchaseForm.taobaoLink" :placeholder="msg('请输入采购链接')"></el-input>
                </el-form-item>
                <el-form-item :label="msg('品名')">
                    <el-input v-model="purchaseForm.productName" :placeholder="msg('请输入品名')"></el-input>
                </el-form-item>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateVisiable = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doUpdates()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Edit from "./PurchaseEdit.vue";
import PurchaseImport from "./PurchaseImport.vue";

export default {
    name: 'purchase',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Edit:Edit,
        PurchaseImport:PurchaseImport
    },
    mounted:function(){
        this.buildColumns();
        this.list();
    },
    data(){
        return {
            query:{
                length: 10,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                xiapiTitle:null,
                shopName:null,
                xiapiLink:null,
                taobaoLink:null,
                productName:null,
                remark:null,
                remark1:null,
                remark2:null,
                remark3:null,
                remark4:null,
                remark5:null,
                remark6:null,
                sku:null,
                variationSku:null,
                Date: [], 
                usedTime:[],
            },
            showQuery: false,
            importVisible:false,
            curr: null,
            columns: [],
            productNames: [],
            dgTitle: '新增采购链接',
            dgVisible: false,
            purchaseEdit: {
                xiapiTitle:null,
                xiapiLink:null,
                taobaoLink:null,
                productName:null,
                remark:null,
                ids:null,
            },
            updateVisiable:false,
            purchaseForm:{
                ids:null,
                taobaoLink:null,
                productName:null,
            }
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'shopName', title: this.msg('店铺名'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'productThumb', title: this.msg('商品图'), minWidth: 80, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let thumb = row.productThumb
                            return thumb?[
                                <el-image  style="width: 80px;height:80px" src={this.$kit.api.imgView+thumb} preview-src-list={[this.$kit.api.imgView+thumb]}></el-image>
                            ]: [thumb];
                        },
                    }
                },
                {field: 'xiapiTitle', title: this.msg('商品店铺标题'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'xiapiLink', title: this.msg('商品店铺链接'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'taobaoLink', title: this.msg('商品采购链接'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'productName', title: this.msg('商品申报品名'), minWidth: 100, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'remark1', title: this.msg('采购价格'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'remark2', title: this.msg('货架号'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'remark3', title: this.msg('规格'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'sku', title: this.msg('SKU'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'variationSku', title: this.msg('货号'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'remark4', title: this.msg('尺码'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'remark5', title: this.msg('采购数量'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                        default: ({ row }) => {
                            let title =  row.remark5;
                            return [
                                <span class="id-cell"><i class="detail-cell el-icon-edit"></i>{title}</span>
                            ];
                        }
                    }
                },
                {field: 'remark6', title: this.msg('批次'), minWidth: 80, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                        default: ({ row }) => {
                            let title =  row.remark6;
                            return [
                                <span class="id-cell"><i class="detail-cell1 el-icon-edit"></i>{title}</span>
                            ];
                        }
                    }
                },
                {field: 'remark', title: this.msg('备注'), minWidth: 60, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'createrName', title: this.msg('创建人'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'created', title: this.msg('创建时间'), minWidth:80, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'usedTime', title: this.msg('引用时间'), minWidth:80, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'usedQty', title: this.msg('引用次数'), minWidth:80, sortable: true},
            ]
        },
        list(refresh){

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var param = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.purchase.list, param)
                .then((result) => {
                    if(result.status){
                        this.productNames = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                if(column.property == 'remark5'){  //打开预览详情
                    if(className.indexOf('detail-cell')>-1){
                        this.updatePurchaseRemark5(row);
                    }
                }else if(column.property == 'remark6'){
                    if(className.indexOf('detail-cell1')>-1){
                        this.updatePurchaseRemark6(row);
                    }
                }else{
                    var table =  this.$refs.dataTable;
                    table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
                }
            }
        },
        updatePurchaseRemark5(row){
            var _this = this;
            this.$prompt('请输入数量', this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.purchase.updatePurchaseRemark5
                    +"?id="+row.id
                    +"&remark5="+(value?value:"")
                ).then((result) => {
                    if(result.status){
                        this.list();
                    }
                    this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        updatePurchaseRemark6(row){
            var _this = this;
            this.$prompt('请输入批次', this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.purchase.updatePurchaseRemark6
                    +"?id="+row.id
                    +"&remark6="+(value?value:"")
                ).then((result) => {
                    if(result.status){
                        this.list();
                    }
                    this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        openedPurchaseEdit(){
            this.$nextTick(() => {
                this.$refs.dgTitleRef.init();
            });
        },
        add(){
            this.purchaseEdit = {
                xiapiTitle:null,
                xiapiLink:null,
                shopName:null,
                taobaoLink:null,
                productName:null,
                remark:null,
                remark1:null,
                remark2:null,
                remark3:null,
                sku:null,
                variationSku:null,
                remark4:null,
                remark5:null,
                remark6:null,
                ids:null,
            };
            this.dgVisible = true;
            this.dgTitle = "新增采购链接";
        },
        edit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            if(selectes.length>1){
                let ids = selectes.map(e => e.id).join(",");
                this.purchaseEdit['ids'] = ids;
                this.purchaseEdit['xiapiTitle'] = null;
                this.purchaseEdit['xiapiLink'] = null;
                this.purchaseEdit['shopName'] = null;
                this.purchaseEdit['taobaoLink'] = null;
                this.purchaseEdit['productName'] = null;
                this.purchaseEdit['remark'] = null;
                this.purchaseEdit['remark1'] = null;
                this.purchaseEdit['remark2'] = null;
                this.purchaseEdit['remark3'] = null;
                this.purchaseEdit['sku'] = null;
                this.purchaseEdit['variationSku'] = null;
                this.purchaseEdit['remark4'] = null;
                this.purchaseEdit['remark5'] = null;
                this.purchaseEdit['remark6'] = null;
            }else{
                this.purchaseEdit = selectes[0];
            }
            this.dgTitle = "编辑采购链接";
            this.dgVisible = true;
        },
        update(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(e => e.id).join(",");
            this.purchaseForm = {
                ids:ids,
                taobaoLink:null,
                productName:null,
            }
            this.updateVisiable = true;
        },
        doUpdates(){//批量更新采购链接
            if(!this.purchaseForm.taobaoLink){
                this.$message(this.msg('请输入采购链接'));
                return;
            }
            if(!this.purchaseForm.productName){
                this.$message(this.msg('请输入品名'));
                return;
            }
            let param = this.purchaseForm;
            this.$axios.post(this.$kit.api.purchase.updates,param)
                .then((result) => {
                    if(result.status){
                        this.$message(result.msg || this.msg('更新成功'));
                        this.updateVisiable = false;
                        this.list();
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                })
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        dgVisibleClose(){
            this.productNameEdit = {
                id : null,
                name : ''
            },
            this.dgVisible = false;
            this.list();
        },
        importVisibleDg(){
            this.importVisible = false;
            this.list();
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            this.$confirm(this.msg('确定要删除选中的数据吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.purchase.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    if(result.status){
                        _this.$message('成功删除'+result.data+'条记录');
                        _this.list();
                    }else{
                        _this.$message(_this.msg('只能删除自己创建的链接！'));
                    }
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
        doImport(){
            this.importVisible = true;
        },
        doCloseImport(done){
            this.list();
            done();
        },
    },
}
</script>

<style lang="scss" scoped>
.detail-cell{cursor:pointer; display:inline-block; margin-right:5px;}
.detail-cell1{cursor:pointer; display:inline-block; margin-right:5px;}
</style>
