<!-- 批量更新 -->
<template>
    <el-form label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('所属类别')">
                    <el-input v-model="title" :placeholder="msg('请输入类别')" size="small"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('最低价格')">
                    <el-input-number v-model="minPrice" :precision="0" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20">
                <el-form-item :label="msg('最高价格')">
                    <el-input-number v-model="maxPrice" :precision="0" :min="0"></el-input-number>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确认')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>

    </el-form>

</template>

<script>
export default {
    name: "categoryBatchEdit",
    components:{
    },
    data(){
        return {
            title:null,
            minPrice:0,
            maxPrice:0,
        }
    },
    mounted(){
    },
    props:{
        batchIds:String,
    },
    methods:{
        init(){
            this.title = null;
            this.minPrice = 0;
            this.maxPrice = 0;
        },
        onSubmit(){
            var _this = this;
            
            if(!this.title){
                this.$message(this.msg('请输入类别'));
                return;
            }
            if(!this.maxPrice || this.maxPrice == 0){
                this.$message(this.msg('请输入最高价格'));
                return;
            }
            let catrgory = {
                batchIds : this.batchIds,
                title : this.title,
                minPrice : this.minPrice,
                maxPrice : this.maxPrice,
            }
            this.$axios.post(this.$kit.api.category.batchUpdate,catrgory)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || _this.msg('update-ok'));
                        _this.$emit("close-dg", true);
                    }else{
                        _this.$message(result.msg || _this.msg('update-ok'));
                    }
                });
        },
    }

}
</script>

<style lang="scss" scoped>

</style>